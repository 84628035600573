<template>
  <div id="app">
    <!-- 天地图 -->
    <!-- <div style="width: 100%;height:85vh;" id="mapDiv">
                </div> -->
    <div class="toptitle">
      桐乡崇福资产管理中心
    </div>
    <div class="mytime">
      {{ nowtime }}
    </div>
    <div class="appcontent">
      <div class="appleft">
        <div class="gailan">
        </div>
        <div class="appleft1">
          <div class="overshadow">
            <div>房源总数</div>
            <!-- <div>{{ housedata[0].count }}</div> -->
            <div>{{ sum }}</div>
          </div>
          <div class="overshadow1">
            <div>出租数</div>
            <div>{{ housedata[3].count }}</div>
          </div>
          <div>
            <div>闲置数</div>
            <div>{{ housedata[4].count }}</div>
          </div>
        </div>

        <div class="appleft2 border-anim2">
          <div class="border-anim2-edge border-anim2-left"></div>
          <div class="border-anim2-edge border-anim2-top"></div>
          <div class="border-anim2-edge border-anim2-right"></div>
          <div class="border-anim2-edge border-anim2-bottom"></div>
          <!-- 左上角三角 -->
          <img class="topletf" src="../public/images/triangle.png" alt="">
          <div class="appleft2-title">各区块的房源套数</div>

          <!-- echarts1 -->
          <div id="mybox1">
            mybox1
          </div>

        </div>
        <div class="appleft3 border-anim2">
          <div class="border-anim2-edge border-anim2-left"></div>
          <div class="border-anim2-edge border-anim2-top"></div>
          <div class="border-anim2-edge border-anim2-right"></div>
          <div class="border-anim2-edge border-anim2-bottom"></div>
          <!-- 左上角三角 -->
          <img class="topletf" src="../public/images/triangle.png" alt="">
          <div class="appleft2-title">出租情况</div>
          <!-- echarts1 -->
          <div id="mybox2">
            mybox2
          </div>

        </div>


      </div>
      <div class="appmid">
        <div class="appmid1 ">

          <div class="appmid1-text1">资产总量</div>
          <!-- <div class="appmid1-text2">{{ housedata[0].count }}</div> -->
          <div class="appmid1-text2">{{ sum }}</div>
          <div class="appmid1-grids">
            <div class="appmid1-grid">
              <div class="appmid1-grid-text1">安置房源</div>
              <div class="appmid1-grid-text2">{{ housetype[0].houseCount }}</div>
            </div>
            <div class="appmid1-grid">
              <div class="appmid1-grid-text1">办公大楼</div>
              <div class="appmid1-grid-text2">{{ housetype[1].houseCount }}</div>
            </div>
            <!-- <div class="appmid1-grid">
              <div class="appmid1-grid-text1">出借房源</div>
              <div class="appmid1-grid-text2">{{ housetype[2].houseCount }}</div>
            </div>
            <div class="appmid1-grid">
              <div class="appmid1-grid-text1">人才公寓</div>
              <div class="appmid1-grid-text2">{{ housetype[3].houseCount }}</div>
            </div> -->
            <div class="appmid1-grid">
              <div class="appmid1-grid-text1">市场商铺</div>
              <div class="appmid1-grid-text2">{{ housetype[4].houseCount }}</div>
            </div>
            <!-- <div class="appmid1-grid">
              <div class="appmid1-grid-text1">销售房源</div>
              <div class="appmid1-grid-text2">{{ housetype[5].houseCount }}</div>
            </div> -->
            <div class="appmid1-grid">
              <div class="appmid1-grid-text1">园区厂房</div>
              <div class="appmid1-grid-text2">{{ housetype[5].houseCount }}</div>
            </div>
            <div class="appmid1-grid">
              <div class="appmid1-grid-text1">直管公房</div>
              <div class="appmid1-grid-text2">{{ housetype[6].houseCount }}</div>
            </div>
          </div>
        </div>
        <div class="appmid2 border-anim2">
          <div class="border-anim2-edge border-anim2-left"></div>
          <div class="border-anim2-edge border-anim2-top"></div>
          <div class="border-anim2-edge border-anim2-right"></div>
          <div class="border-anim2-edge border-anim2-bottom"></div>
          <img class="left-top" src="../public/images/pic_map_corner_1.png" alt="">
          <img class='right-top' src="../public/images/pic_map_corner_2.png" alt="">
          <img class='left-bottom' src="../public/images/pic_map_corner_3.png" alt="">
          <img class='right-bottom' src="../public/images/pic_map_corner_4.png" alt="">
          <!-- mapDiv -->

          <!-- @click="changefuc" -->
          <div id="mapDiv" class="mapDiv" @dblclick="changefuc()"></div>
        </div>
        <div class="appmid3 border-anim2">
          <div class="border-anim2-edge border-anim2-left"></div>
          <div class="border-anim2-edge border-anim2-top"></div>
          <div class="border-anim2-edge border-anim2-right"></div>
          <div class="border-anim2-edge border-anim2-bottom"></div>
          <!-- 左上角三角 -->
          <img class="topletf" src="../public/images/triangle.png" alt="">
          <div class="appleft2-title">合同情况</div>
          <div id="mybox4">mybox4</div>
        </div>

      </div>
      <el-drawer title="我是标题" :visible.sync="ischange" direction="ttb" :with-header="false" size="90%">
        <div id="mapDiv1" class="changetrue"></div>
        <div style="width: 100%;height: 10vh;display: flex;flex-direction: column;">
          <div class="content" align="center">
            <el-button type="primary" class="button" @click="zoomIn">放大地图</el-button>
            <el-button type="primary" class="button" @click="zoomOut">缩小地图</el-button>
            <el-button type="primary" class="button" @click="moveRight">向右移动</el-button>
            <el-button type="primary" class="button" @click="moveLeft">向左移动</el-button>
            <el-button type="primary" class="button" @click="moveUp">向上移动</el-button>
            <el-button type="primary" class="button" @click="moveDown">向下移动</el-button>
            <el-button type="primary" class="button" @click="getBounds">获取地图范围</el-button>
            <el-button type="primary" class="button" @click="getCenter">获取地图中心点坐标</el-button>
          </div>
          <div class="content" align="center">
            <el-button type="primary" class="button" @click="setMapType('TMAP_NORMAL_MAP')">普通街道视图</el-button>
            <el-button type="primary" class="button" @click="setMapType('TMAP_SATELLITE_MAP')">卫星视图</el-button>
            <el-button type="primary" class="button" @click="setMapType('TMAP_HYBRID_MAP')">卫星和路网的混合视图</el-button>
            <el-button type="primary" class="button" @click="setMapType('TMAP_TERRAIN_MAP')">地形视图</el-button>
            <el-button type="primary" class="button" @click="setMapType('TMAP_TERRAIN_HYBRID_MAP')">地形和路网的混合视图</el-button>
            <el-button type="primary" class="button" @click="getZoom">获取地图缩放级别</el-button>
            <el-button type="primary" class="button" @click="getCode">获取地图投影类型</el-button>
            <el-button type="primary" class="button" @click="setCenterAndZoom">设置经纬度和缩放级别</el-button>
            <el-button type="primary" class="button" @click="search">搜索</el-button>
          </div>
        </div>
      </el-drawer>
      <div class="appright">
        <div class="appright1 border-anim2">
          <div class="border-anim2-edge border-anim2-left"></div>
          <div class="border-anim2-edge border-anim2-top"></div>
          <div class="border-anim2-edge border-anim2-right"></div>
          <div class="border-anim2-edge border-anim2-bottom"></div>
          <!-- 租户姓名：{{ zujin.hirerName }} -->
          <div style="overflow-y: scroll;" :class="{ anim: animate == true }">
            <div class="appright1-div" v-for="(i, n) in zujinout" :key="n">
              开始时间：{{ String(i.createDate).split("T")[0] }} 第{{ i.paidPeriods }}期 房源名称：{{ i.houseName }} 租金应缴时间：{{
                i.needDate }}

            </div>



          </div>

        </div>
        <div class="appright2 border-anim2">
          <div class="border-anim2-edge border-anim2-left"></div>
          <div class="border-anim2-edge border-anim2-top"></div>
          <div class="border-anim2-edge border-anim2-right"></div>
          <div class="border-anim2-edge border-anim2-bottom"></div>
          <!-- 左上角三角 -->
          <img class="topletf" src="../public/images/triangle.png" alt="">
          <div class="appleft2-title">经营情况</div>
          <div class="appright2-grids">
            <div class="appright2-grid">
              <div class="appmid1-grid-text1">收入总额</div>
              <div class="appmid1-grid-text2">{{ yearPaidMoney + '万元' }}</div>
            </div>
            <div class="appright2-grid">
              <div class="appmid1-grid-text1">总租赁面积</div>
              <div class="appmid1-grid-text2">{{ jyqk[0].count }}</div>
            </div>
            <div class="appright2-grid">
              <div class="appmid1-grid-text1">出租率</div>
              <div class="appmid1-grid-text2">{{ jyqk[1].count }}</div>
            </div>
            <!-- <div class="appright2-grid">
              <div class="appmid1-grid-text1">累计应收总额</div>
              <div class="appmid1-grid-text2">{{ jyqk[3].count }}</div>
            </div> -->
          </div>
        </div>
        <div class="appright3 border-anim2">
          <div class="border-anim2-edge border-anim2-left"></div>
          <div class="border-anim2-edge border-anim2-top"></div>
          <div class="border-anim2-edge border-anim2-right"></div>
          <div class="border-anim2-edge border-anim2-bottom"></div>
          <!-- 左上角三角 -->
          <img class="topletf" src="../public/images/triangle.png" alt="">
          <div class="appleft2-title">土地情况</div>
          <div class="mycards">
            <div class="mycard" v-for="(i, n) in contentdata5" :key="n">
              <div>{{ i.name }}</div>
              <!-- {{ i.name=='土地面积' ? '亩':'' }} -->
              <div class="jixie">{{ i.value }}</div>
            </div>

          </div>
          <div id="mybox5">mybox5</div>
        </div>

      </div>
    </div>

  </div>
</template>


<script>
import { Icon } from 'element-ui';
import sessionUtils from './sessionUtil'
import axios from 'axios';
import moment from 'moment';
// import L from 'leaflet'
// import 'leaflet/dist/leaflet.css'
var ROOT_PATH = 'https://echarts.apache.org/examples';
// let map
let T = window.T;
let MarkerClusterer = T.MarkerClusterer;

export default {
  name: 'App',
  data() {
    return {
      itime: undefined,
      timer: undefined,
      aaaa: undefined,
      markers: undefined,
      i: 0.1,
      areaname: '',
      map1: undefined,
      map: undefined,
      ischange: false,
      animate: false,
      // 合同情况
      hetongtype: [0, 0, 0, 0],
      //房源数组
      housedata:
        [{ name: "房源总数", count: 0 },
        { name: "经营性房源", count: 0 },
        { name: "非经营性房源", count: 0 },
        { name: "出租房源", count: 0 }, { name: "出租房源", count: 0 }],
      //  房源类型数组
      housetype: [{ 'houseCount': 0, house_type: "azfy" },
      { 'houseCount': 0, house_type: "bgdl" },
      { 'houseCount': 0, house_type: "cjfy" },
      { 'houseCount': 0, house_type: "rcgy" },
      { 'houseCount': 0, house_type: "scsp" },
      { 'houseCount': 0, house_type: "xsfy" },
      { 'houseCount': 0, house_type: "yqcf" },
      { 'houseCount': 0, house_type: "zggf" }],
      // 区块房源套数
      titledata1: [],
      contentdata1: [],
      titledata2: [],
      contentdata2: [],
      titledata5: [],
      contentdata5: [],
      yearPaidMoney: '',
      jyqk: [{ name: "年度总租赁面积", count: "0" },
      { name: "年度出租率", count: "0" },
      { name: "本月平均租金", count: "0" },
      { name: "本年累计应收总额", count: "0" },
      { name: "本年累计收缴率", count: "0" }],

      azdata0: '',
      azdata1: '',
      azdata2: '',
      azdata3: '',
      azdata4: '',
      contractAreaData0: '',
      contractAreaData1: '',
      contractAreaData2: '',
      contractAreaData3: '',
      contractAreaData4: '',
      contractAreaData5: '',
      myhour: undefined,
      myminutes: undefined,
      myseconds: undefined,
      nowtime: undefined,
      mytime: undefined,
      myyear: undefined,
      myday: undefined,
      fRightData: [],
      allData: [],
      sLeftData: [],
      fee: '',
      rentColors1: ['#6DCCD3', 'white'],
      rentColors2: ['white', '#6DCCD3'],
      rentRate: 0,
      propertyRate: 0,
      property: 0,
      rentCount: 0,
      baseData: '',
      dialogVisible: false,
      houseAllNumber: 1,
      houseInfoGroup: [],

      general: {},

      neededTypes : ["azfy", "bgdl", "scsp", "yqcf", "zggf"],


      hetong: {},
      zujin: {},
      zujinout: []

    }
  },

  mounted() {
    // this.initMap1()
    this.myinitMap()
    // clearImmediate(itime1)
    // let itime1 = setInterval(() => {
    //   this.getAllData()
    //   this.getdata()
    //   this.myinitMap()
    // }, 21600000)
    // this.init4()
  },
  created() {
    this.getcurrenttime();
    this.itime = setInterval(() => {
      this.getcurrenttime();
    }, 1000)

    this.getAllData()
    this.getdata()
    this.scroll()

  },
  destroyed() {
    clearInterval(this.timer);
    clearInterval(this.itime);
    clearInterval(this.scrollbar);
  },
  computed: {
    sum() {
      return this.housetype
        .filter(item => this.neededTypes.includes(item.house_type))
        .reduce((total, item) => total + item.houseCount, 0);
    }
  },
  methods: {

    // getSum: function () {
    //   const neededTypes = ["azfy", "bgdl", "scsp", "yqcf", "zggf"];
    //   const results = this.housetype.filter(item => neededTypes.includes(item.house_type)).reduce((sum, item) => sum + item.houseCount, 0);
    //   return results
    // },

    // 聚合点
    setMapType(type) {
      const mapType = eval(type);
      this.map1.removeControl(mapType);
      this.map1.addControl(mapType);
    },
    setCenterAndZoom() {
      const lng = prompt("请输入经度");
      const lat = prompt("请输入纬度");
      const zoom = prompt("请输入缩放级别");
      this.map1.centerAndZoom(new T.LngLat(lng, lat), zoom);
    },
    zoomIn() {
      this.map1.zoomIn()
    },
    zoomOut() {
      this.map1.zoomOut()
    },
    moveRight() {
      this.map1.panTo(new T.LngLat(this.map1.getCenter().getLng() + this.i, this.map1.getCenter().getLat()))
    },
    moveLeft() {
      this.map1.panTo(new T.LngLat(this.map1.getCenter().getLng() - this.i, this.map1.getCenter().getLat()))
    },
    moveUp() {
      this.map1.panTo(new T.LngLat(this.map1.getCenter().getLng(), this.map1.getCenter().getLat() + this.i))
    },
    moveDown() {
      this.map1.panTo(new T.LngLat(this.map1.getCenter().getLng(), this.map1.getCenter().getLat() - this.i))
    },
    getBounds() {
      // 获取可视区域
      let bs = this.map1.getBounds()
      // 可视区域左下角
      let bssw = bs.getSouthWest()
      // 可视区域右上角
      let bsne = bs.getNorthEast()
      this.$message(`当前地图可视范围是：${bssw.lng},${bssw.lat}到${bsne.lng},${bsne.lat}`)
    },
    getCenter() {
      this.$message(`当前地图中心点坐标：${this.map1.getCenter().getLng()},${this.map1.getCenter().getLat()}`)
    },
    getZoom() {
      this.$message(`当前地图缩放级别：${this.map1.getZoom()}`)
    },
    getCode() {
      this.$message(`地图投影类型信息是：${this.map1.getCode()}`)
    },
    search() {
      let that = this
      this.$prompt('输入地址，并确认', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(({ value }) => {
        that.map1.clearOverLays()
        let geocoder = new T.Geocoder()
        geocoder.getPoint(value, function (result) {
          if (result.getStatus() == 0) {
            that.map1.panTo(result.getLocationPoint(), 16)
            let marker = new T.Marker(result.getLocationPoint())
            that.map1.addOverLay(marker)
            marker.addEventListener('click', function (e) {
              alert(JSON.stringify(e.lnglat))
            })
          } else {
            alert(result.getMsg())
          }
        })
      })
    },
    areasearch() {
      console.log("map1", this.map1)
      this.map1.clearOverLays();
      var geocoder = new T.Geocoder();
      let adress = this.areaname
      geocoder.getPoint(adress, function (result) {
        if (result.getStatus() == 0) {
          // this.map1.panTo(result.getLocationPoint(), 16);
          //创建标注对象
          var marker = new T.Marker(result.getLocationPoint());
          //向地图上添加标注
          // this.map1.addOverLay(marker);
          marker.addEventListener("click", function (e) {
            // layer.alert(JSON.stringify(e.lnglat));
          });
        } else {
          // alert(result.getMsg());
        }

      });
    },
    scroll() {
      this.animate = true; // 因为在消息向上滚动的时候需要添加css3过渡动画，所以这里需要设置true
      let index = 1
      this.$get('api/baseData/getRentOutDue', { userId: 1, pageSize: 2, pageNum: index }).then(res => {
        this.zujinout = res.data.list
        if (this.zujinout.length > 0) {
          index += 1
        } else {
          index = 0
        }
      })
      this.animate = false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      this.scrollbar = setInterval(() => {
        this.$get('api/baseData/getRentOutDue', { userId: 1, pageSize: 2, pageNum: index }).then(res => {
          this.zujinout = res.data.list
          if (this.zujinout.length > 0) {
            index += 1
          } else {
            index = 0
          }
        })
        this.animate = false; // margin-top 为0 的时候取消过渡动画，实现无缝滚动
      }, 5000);
    },


    // 获取数据
    getdata() {
      // 合同情况
      this.$get('api/baseData/getContractData', { userId: 1 }).then(res => {
        this.hetongtype = [res.data.出租合同, res.data.续租合同, res.data.转租合同, res.data.退租合同]
        this.init4()
      })
      this.$get('api/baseData/getContractDue', { userId: 1 }).then(res => {
        this.hetong = res.data
      })
      this.$get('api/baseData/getRentDue', { userId: 1 }).then(res => {
        this.zujin = res.data
      })
      this.$get('api/baseData/getRentOutDue', { userId: 1, pageSize: 10, pageNum: 1 }).then(res => {
        this.zujinout = res.data
      })


      this.$get('api/baseData/getHouseRent', { userId: 1 }).then(res => {
        let oo = []
        let ee = []
        res.data.forEach(i => {
          if (i.house_type == "azfy") {
            oo.push('安置房源')
            ee.push({ value: i.houseCount, name: '安置房源' })
          } else
            if (i.house_type == "bgdl") {
              oo.push('办公大楼')
              ee.push({ value: i.houseCount, name: '办公大楼' })
            } else
              if (i.house_type == "scsp") {
                oo.push('市场商铺')
                ee.push({ value: i.houseCount, name: '市场商铺' })
              } else
                if (i.house_type == "xsfy") {
                  oo.push('销售房源')
                  ee.push({ value: i.houseCount, name: '销售房源' })
                } else
                  if (i.house_type == "yqcf") {
                    oo.push('园区厂房')
                    ee.push({ value: i.houseCount, name: '园区厂房' })
                  }
        })

        this.titledata2 = oo
        this.contentdata2 = ee
        this.init2()
      }),
        this.$get('api/baseData/getHouseNumberByArea', { userId: 1 }).then(res => {
          let oo = []
          let ee = []
          res.data.forEach(i => {
            oo.push(i.houseName)
            ee.push(i.houseCount)
          })

          this.titledata1 = oo
          this.contentdata1 = ee
          this.init1()
        })
      this.$post1('api/baseData/getAllData', { createdBy: 1 }).then(res => {
        this.housedata = res.data.houseCount
      })
      this.$get('api/baseData/getHouseNumberByHouseType', { userId: 1 }).then(res => {
        console.log(res, 1111);
        this.housetype = res.data
      })

    },
    // 更新时间
    getcurrenttime() {
      var date = new Date();

      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      month = month < 10 ? "0" + month : month;
      var day = date.getDate();
      day = day < 10 ? "0" + day : day;
      var week = "日一二三四五六".charAt(date.getDay()); // 使用charAt函数提取相应汉字
      var hour = date.getHours();
      hour = hour < 10 ? "0" + hour : hour; // 用三目运算符调整数字显示格式
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;

      var current = year + "-" + month + "-" + day + " " + "星期" + week + " " + hour + ":" + minute + ":" + second;

      this.nowtime = current

    },
    // 获取数据
    getAllData() {
      this.$post1("api/baseData/getAllData", {
        createdBy: 1,
      }).then((response) => {

        this.allData = response.data;
        let housecount = response.data.houseCount
        housecount.forEach(i => {
          this.housedata.push({ value: i.count, name: i.name })
        })
        var that = this;

        this.contractAreaData0 = response.data.contractAreaData[0].value
        this.contractAreaData1 = response.data.contractAreaData[1].value
        this.contractAreaData2 = response.data.contractAreaData[2].value
        this.contractAreaData3 = response.data.contractAreaData[3].value
        this.contractAreaData4 = response.data.contractAreaData[4].value
        this.contractAreaData5 = response.data.contractAreaData[5].value
        this.azdata0 = response.data.azData[0].value
        this.azdata1 = response.data.azData[1].value
        this.azdata2 = response.data.azData[2].value
        this.azdata3 = response.data.azData[3].value
        this.azdata4 = response.data.azData[4].value
        let aa = [
          { rentMonth: "1月", total: "0" },
          { rentMonth: "2月", total: "0" },
          { rentMonth: "3月", total: "0" },
          { rentMonth: "4月", total: "0" },
          { rentMonth: "5月", total: "0" },
          { rentMonth: "6月", total: "0" },
          { rentMonth: "7月", total: "0" },
          { rentMonth: "8月", total: "0" },
          { rentMonth: "9月", total: "0" },
          { rentMonth: "10月", total: "0" },
          { rentMonth: "11月", total: "0" },
          { rentMonth: "12月", total: "0" },
        ];
        this.sLeftData = [];
        this.yearPaidMoney = Math.floor(+this.allData.yearPaidMoney / 1000);
        // mybox5
        this.contentdata5 = this.allData.LandCondition;
        this.contentdata6 = [this.contentdata5[2], { name: '未出租数量', value: this.contentdata5[0].value - this.contentdata5[2].value }];
        this.contentdata5.forEach(i => {
          this.titledata5.push(i.name)
        })
        this.init5()
        this.jyqk = response.data.rentData
      });
    },

    init2() {
      // mybox2

      this.myChart2 = this.$echarts.init(document.getElementById('mybox2'));
      const weatherIcons = {
        Sunny: ROOT_PATH + '/data/asset/img/weather/sunny_128.png',
        Cloudy: ROOT_PATH + '/data/asset/img/weather/cloudy_128.png',
        Showers: ROOT_PATH + '/data/asset/img/weather/showers_128.png'
      };
      let option2 = {
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 10,
          left: 'center',
          data: this.titledata2,
          textStyle: { //图例文字的样式
            color: 'white',
            fontSize: 12
          },
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['50%', '45%'],
            selectedMode: 'single',
            data: this.contentdata2,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      option2 && this.myChart2.setOption(option2, true);
    },
    init1() {
      // mybox1
      this.myChart1 = this.$echarts.init(document.getElementById('mybox1'));
      let option1 = {
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
        },
        grid: {
          top: '6%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          show: false,
          type: 'value',
          boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: 'category',
          nameTextStyle: 'white',
          data: '',
          axisLabel: {
            textStyle: {
              color: 'white',
              rich: {
                value: {
                  lineHeight: 30,
                  align: 'center'
                },
              }
            }
          }
        },
        series: [
          {
            name: '',
            showBackground: true,
            type: 'bar',
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.1)'
            },
            data: [],
            label: {
              show: true,
              position: 'right',
              valueAnimation: true,

            },
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(
                //前四个参数用于配置渐变色的起止位置，这四个参数依次对应 右下左上 四个方位。也就是从右边开始顺时针方向。
                //通过修改前4个参数，可以实现不同的渐变方向
                /*第五个参数则是一个数组，用于配置颜色的渐变过程。
                  每项为一个对象，包含offset和color两个参数
                */
                0, 0, 1, 0, [{//代表渐变色从正上方开始
                  offset: 0, //offset范围是0~1，用于表示位置，0是指0%处的颜色
                  color: '#005BEA'
                }, //柱图渐变色
                {
                  offset: 1, //指100%处的颜色
                  color: '#00C6FB'
                }
              ]
              ),
            }
          }
        ]
      };
      this.myChart1.setOption(option1, true)
      option1 && this.myChart1.setOption(option1);
      let index = 100;
      let pageSize = 10;
      let totalData = [...this.contentdata1]; //假设需要展示的数据存储在 this.contentdata5 数组中
      let titledata = [...this.titledata1]
      let tempData = [];
      let temptitle = []
      temptitle = titledata.slice(index, index + pageSize);
      tempData = totalData.slice(index, index + pageSize);
      if (tempData.length > 0 && temptitle.length > 0) {
        index += pageSize;
      } else {
        index = 0
      }

      option1.series[0].data = tempData;
      option1.yAxis.data = temptitle
      this.myChart1.setOption(option1, true);
      this.timer = setInterval(() => {
        temptitle = titledata.slice(index, index + pageSize);
        tempData = totalData.slice(index, index + pageSize);
        console.log('temptitle', temptitle, 'tempData', tempData, index)
        if (tempData.length > 0 && temptitle.length > 0) {
          index += pageSize;
          // 标记
        } else {
          index = 0
        }

        option1.series[0].data = tempData;
        option1.yAxis.data = temptitle
        this.myChart1.setOption(option1, true);
      }, 15000);
    },
    init4() {
      // mybox4

      let xData = ["出租合同", "续租合同", "转租合同", "退租合同"];
      // this.hetongtype
      let yData = this.hetongtype

      this.myChart4 = this.$echarts.init(document.getElementById('mybox4'));
      let option4 = {
        // backgroundColor: '#061326',
        "grid": {
          "top": "15%",
          "left": "0%",
          "bottom": "0%",
          "right": "0%",
          "containLabel": true
        },
        tooltip: {
          show: true
        },
        animation: false,
        "xAxis": [{
          "type": "category",
          "data": xData,
          "axisTick": {
            "alignWithLabel": true
          },
          "nameTextStyle": {
            "color": "#82b0ec"
          },
          "axisLine": {
            show: false,
            "lineStyle": {
              "color": "#82b0ec"
            }
          },
          "axisLabel": {
            "textStyle": {
              "color": "#fff"
            },
            margin: 30
          }
        }],
        "yAxis": [{
          show: false,
          "type": "value",
          "axisLabel": {
            "textStyle": {
              "color": "#fff"
            },
          },
          "splitLine": {
            "lineStyle": {
              "color": "#0c2c5a"
            }
          },
          "axisLine": {
            "show": false
          }
        }],
        "series": [{
          "name": "",
          type: 'pictorialBar',
          symbolSize: [40, 10],
          symbolOffset: [0, -6],
          symbolPosition: 'end',
          z: 12,
          // "barWidth": "0",
          "label": {
            "normal": {
              "show": true,
              "position": "top",
              // "formatter": "{c}%"
              fontSize: 15,
              fontWeight: 'bold',
              color: '#34DCFF'
            }
          },
          color: "#2DB1EF",
          data: yData
        },
        {
          name: '',
          type: 'pictorialBar',
          symbolSize: [40, 10],
          symbolOffset: [0, 7],
          // "barWidth": "20",
          z: 12,
          "color": "#2DB1EF",
          "data": yData
        },
        {
          name: '',
          type: 'pictorialBar',
          symbolSize: [50, 15],
          symbolOffset: [0, 12],
          z: 10,
          itemStyle: {
            normal: {
              color: 'transparent',
              borderColor: '#2EA9E5',
              borderType: 'solid',
              borderWidth: 1
            }
          },
          data: yData
        },
        {
          name: '',
          type: 'pictorialBar',
          symbolSize: [70, 20],
          symbolOffset: [0, 18],
          z: 10,
          itemStyle: {
            normal: {
              color: 'transparent',
              borderColor: '#19465D',
              borderType: 'solid',
              borderWidth: 2
            }
          },
          data: yData
        },
        {
          type: 'bar',
          //silent: true,
          "barWidth": "40",
          barGap: '10%', // Make series be overlap
          barCateGoryGap: '10%',
          itemStyle: {
            normal: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 0.7, [{
                offset: 0,
                color: "#38B2E6"
              },
              {
                offset: 1,
                color: "#0B3147"
              }
              ]),
              opacity: .8
            },
          },
          data: yData
        }
        ]
      }
      option4 && this.myChart4.setOption(option4);
    },
    init5() {
      // mybox5

      this.myChart5 = this.$echarts.init(document.getElementById('mybox5'));
      const weatherIcons = {
        Sunny: ROOT_PATH + '/data/asset/img/weather/sunny_128.png',
        Cloudy: ROOT_PATH + '/data/asset/img/weather/cloudy_128.png',
        Showers: ROOT_PATH + '/data/asset/img/weather/showers_128.png'
      };
      let option5 = {
        grid: {
          containLabel: true,
          top: '10%',
          bottom: '10%'
        },
        title: {
          text: '',
          subtext: '',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          // formatter: '{a} <br/>{b} : {c} ({d}%)'
        },
        legend: {
          bottom: 10,
          left: 'center',
          data: ['未出租数量', '出租数量'],
          textStyle: { //图例文字的样式
            color: 'white',
            fontSize: 12
          },
        },
        series: [
          {
            type: 'pie',
            radius: '55%',
            center: ['50%', '40%'],
            selectedMode: 'single',
            data: this.contentdata6,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      option5 && this.myChart5.setOption(option5);
    },
    myinitMap() {
      const map = new T.Map('mapDiv')
      // window.map = map
      this.map = map
      const mapType = eval('TMAP_SATELLITE_MAP');
      this.map.removeControl(mapType);
      this.map.addControl(mapType);
      // this.map.enableDrag()
      this.findAllPosition()

    },
    initMap1() {
      // window.returnCitySN.cip
      let ip = '123.158.110.20'
      const map1 = new T.Map('mapDiv1')
      this.map1 = map1
      // this.map1.enableDrag()
      const mapType = eval('TMAP_HYBRID_MAP');
      this.map1.removeControl(mapType);
      this.map1.addControl(mapType);
      this.findAllPosition1()
      // 添加矢量数据图层
      this.map1.on('tianditu_loaded', () => {
        this.addClusteredLayer();
      });
    },
    findAllPosition1() {
      let that = this;
      this.$post1('api/houseinfo/findAllPosition', { createdBy: 1 })
        .then((res) => {
          let arrayObj = [];
          if (res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].lng != null && res.data[i].lng !== '' && res.data[i].lat != null && res.data[i].lat !== '') {
                let lnglat = new T.LngLat(120.4358, 30.5375);
                this.map1.centerAndZoom(lnglat, 15);
                let marker = new T.Marker(new T.LngLat(res.data[i].lng, res.data[i].lat));
                marker.addEventListener("click", function () {
                  let purpose = '';
                  switch (res.data[i].use) {
                    case "1":
                      purpose = "出租住宅";
                      break;
                    case "2":
                      purpose = "办公用房";
                      break;
                    case "3":
                      purpose = "商业用房";
                      break;
                    case "4":
                      purpose = "工业用房";
                      break;
                    case "5":
                      purpose = "车位";
                      break;
                    default:
                      purpose = "";
                  }
                  that.$post1('api/houseinfo/findHouseInfoDetail', { id: res.data[i].id })
                    .then((response) => {
                      if (response.data) {
                        let useStatus = "";
                        if (response.data.useStatus === 0) {
                          useStatus = "闲置";
                        }
                        if (response.data.useStatus === 1) {
                          useStatus = "自用";
                        }
                        // 省略了其他选项

                        let content = "<div style='display: flex;flex-direction: column'>";
                        if (response.data.sceneImages.length > 0) {
                          content += "<img src='" + response.data.sceneImages[0].filePath + "'></img>";
                        }
                        content += "<span>资产状态：" + useStatus + "</span>";
                        content += "<span>承租人：" + response.data.hirerName + "</span>";
                        content += "<span>手机号：" + response.data.telephone + "</span>";
                        content += "<span>房源名称：" + response.data.houseName + "</span>";
                        content += "<span>建筑面积：" + response.data.coveredArea + "㎡" + "</span>";
                        content += "<span>租金：" + response.data.rentMoney + "元" + "</span>";
                        content += "<span>取得日期：" + response.data.receiveDate + "</span>";
                        content += "<span>房屋用途：" + purpose + "</span>";
                        content += "<span>房源数量：" + res.data[i].houseNumber + "</span>";
                        content += "<span>房间数：" + res.data[i].roomTotal + "</span>";
                        content += "<span>是否危房：" + res.data[i].dangerous + "</span>";
                        content += "</div>";

                        var infoWin1 = new T.InfoWindow();
                        infoWin1.setContent(content);
                        marker.openInfoWindow(infoWin1);
                      }
                    });
                });
                arrayObj.push(marker);
              }
            }

            that.markers = new T.MarkerClusterer(this.map1, { markers: arrayObj });
            this.aaaa = arrayObj
            that.markers.setMaxZoom(18)
            that.markers.setStyles(
              [
                {
                  // url:'http://api.tianditu.gov.cn/img/map/cluster/heart30.png',
                  size: [30, 26], //图片大小
                  offset: new T.Point(-15, -13), //显示图片的偏移量
                  textColor: '#000000', //显示数字的颜色
                  textSize: 8,//显示文字的大小
                  range: [0, 50],
                }, {
                  // url:'http://api.tianditu.gov.cn/img/map/cluster/heart40.png',
                  size: [42, 36],
                  offset: new T.Point(-20, -17),
                  textColor: '#ff0000',
                  textSize: 10,
                  range: [50, 400],
                }, {
                  // url:'http://api.tianditu.gov.cn/img/map/cluster/heart50.png',
                  size: [52, 46],
                  soffset: new T.Point(-10, -22),
                  textColor: 'white',
                  textSize: 12,
                  range: [400, 500],
                }
              ]
            );
          }
        })

    },
    findAllPosition() {
      let that = this
      this.$post1('api/houseinfo/findAllPosition', { createdBy: 1 })
        .then((res) => {
          let arrayObj = [];
          if (res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              if (res.data[i].lng != null && res.data[i].lng !== '' && res.data[i].lat != null && res.data[i].lat !== '') {
                let lnglat = new T.LngLat(120.4358, 30.5375)  //response.data.data 为 null
                this.map.centerAndZoom(lnglat, 15);
                let marker = new T.Marker(new T.LngLat(res.data[i].lng, res.data[i].lat))
                // marker.addEventListener("dblclick",function () {
                //     that.goToHouseList(res.data[i].lng, res.data[i].lat)
                // })
                marker.addEventListener("click", function () {
                  that.$post1('api/houseinfo/findHouseInfoDetail', { id: res.data[i].id })
                    .then((response) => {
                      if (response.data) {
                        let useStatus = ""
                        if (response.data.useStatus === 0) {
                          useStatus = "闲置"
                        }
                        if (response.data.useStatus === 1) {
                          useStatus = "自用"
                        }
                        if (response.data.useStatus === 3) {
                          useStatus = "在租"
                        }
                        if (response.data.useStatus === 4) {
                          useStatus = "在借"
                        }
                        if (response.data.useStatus === 5) {
                          useStatus = "待租"
                        }
                        if (response.data.useStatus === 6) {
                          useStatus = "待借"
                        }
                        if (response.data.useStatus === 7) {
                          useStatus = "待售"
                        }
                        if (response.data.useStatus === 8) {
                          useStatus = "待征迁"
                        }
                        if (response.data.useStatus === 9) {
                          useStatus = "待征迁"
                        }
                        if (response.data.useStatus === 10) {
                          useStatus = "已征迁"
                        }

                        let purpose = ""
                        if (response.data.purpose === 0) {
                          purpose = "办公用房"
                        }
                        if (response.data.purpose === 1) {
                          purpose = "经营性用房"
                        }
                        if (response.data.purpose === 2) {
                          purpose = "安置房"
                        }
                        if (response.data.purpose === 3) {
                          purpose = "人才公寓"
                        }
                        if (response.data.purpose === 4) {
                          purpose = "产业园区"
                        }
                        let hirerName = response.data.hirerName ? response.data.hirerName : ""
                        let telephone = response.data.telephone ? response.data.telephone : ""
                        let houseName = response.data.houseName ? response.data.houseName : ""
                        let coveredArea = response.data.coveredArea ? response.data.coveredArea : ""
                        let rentMoney = response.data.rentMoney ? response.data.rentMoney : ""
                        let receiveDate = response.data.receiveDate ? response.data.receiveDate : ""
                        let houseNumber = res.data[i].houseNumber ? res.data[i].houseNumber : ""
                        let roomTotal = res.data[i].roomTotal ? res.data[i].roomTotal : ""
                        let dangerous = res.data[i].dangerous ? res.data[i].dangerous : ""
                        let content = "<div style='display: flex;flex-direction: column'>"
                        if (response.data.sceneImages.length > 0) {
                          content = content + "<img src='" + response.data.sceneImages[0].filePath + "'></img>"
                        }
                        content = content + "<span>资产状态：" + useStatus + "</span>"
                        content = content + "<span>承租人：" + hirerName + "</span>"
                        content = content + "<span>手机号：" + telephone + "</span>"
                        content = content + "<span>房源名称：" + houseName + "</span>"
                        content = content + "<span>建筑面积：" + coveredArea + "㎡" + "</span>"
                        content = content + "<span>租金：" + rentMoney + "元" + "</span>"
                        content = content + "<span>取得日期：" + receiveDate + "</span>"
                        content = content + "<span>房屋用途：" + purpose + "</span>"
                        content = content + "<span>房源数量：" + houseNumber + "</span>"
                        content = content + "<span>房间数：" + roomTotal + "</span>"
                        content = content + "<span>是否危房：" + dangerous + "</span>"
                        content = content + "</div>"
                        var infoWin1 = new T.InfoWindow();
                        infoWin1.setContent(content);
                        marker.openInfoWindow(infoWin1);
                      }

                    })

                });// 将标注添加到地图中
                arrayObj.push(marker)
              }
            }

            that.markers = new T.MarkerClusterer(this.map, { markers: arrayObj });
          }
        })
    },
    //  年收入趋势
    initSleft() {
      this.sleftEchart = this.$echarts.init(document.getElementById("amiddboxtbott1"));
      const sLeftOptions = {
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ], axisLabel: {
            show: true,
            color: '#ffffff',
            fontSize: 12
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            color: '#ffffff',
            fontSize: 12
          }
        },
        series: [
          {
            smooth: true,
            data: this.sLeftData,
            type: "line",
            itemStyle: {
              normal: {
                color: '#8cd5c2', //改变折线点的颜色
                lineStyle: {
                  color: '#35b690' //改变折线颜色
                }
              }
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };
      sLeftOptions && this.sleftEchart.setOption(sLeftOptions);
    },
    // 圆圈
    initSright() {
      this.srightEchart = this.$echarts.init(document.getElementById('aleftboxtmiddr'));
      const srightEchartOptions = {

        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: 'vertical',
          x: 'right',      //可设定图例在左、右、居中
          y: 'center',     //可设定图例在上、下、居中
          padding: [0, -10, 0, 0],
          textStyle: {
            color: '#ffffff',
            fontSize: 14
          }
        },

        series: [
          {
            // name: "Access From",
            type: "pie",
            radius: ["40%", "70%"],
            center: ["20%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },

            emphasis: {
              label: {
                show: true,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: this.sReftData,
            color: ['#eb8611', '#8053cb', '#5376e7', '#16ab86', '#7486a3', '#f6c84a'],
          },
        ],
      };
      srightEchartOptions && this.srightEchart.setOption(srightEchartOptions);
    },
    // 出租面积
    initFLeft() {
      this.fourthLeft = this.$echarts.init(document.getElementById("arightboxbott"));

      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        toolbox: {
          feature: {
            // dataView: {show: true, readOnly: false},
            // magicType: {show: true, type: ["line", "bar"]},
            // restore: {show: true},
            // saveAsImage: {show: true},
          },
        },
        color: ['#4f76e6', '#1baa89'],
        legend: {

          data: ["可出租面积", "已出租面积", "出租率"],
          textStyle: {
            color: '#ffffff',
            fontSize: 14
          }
        },
        xAxis: [
          {
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              show: true,
              color: '#ffffff',
              fontSize: 12
            }
          },
        ],
        yAxis: [

          {
            type: "value",
            name: "可出租面积/已出租面积",
            min: 0,
            axisLabel: {
              formatter: "{value} ㎡",
              color: '#fff',
            },
          },
          {
            type: "value",
            name: "出租率",
            min: 0,
            interval: 5,
            axisLabel: {
              formatter: "{value} %",
              color: '#fff',
            },
          },
        ],
        series: [
          {
            name: "可出租面积",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " ㎡";
              },
            },
            data: [0, 0, 0, 0, 0, 0, 4762, 7849, 26841, 26841, 26841, 24686],
          },
          {
            name: "已出租面积",
            type: "bar",
            tooltip: {
              valueFormatter: function (value) {
                return value + " ㎡";
              },
            },

            data: [0, 0, 0, 0, 0, 0, 5783, 182.2, 48.7, 0, 0, 0],
          },
          {
            name: "出租率",
            type: "line",
            yAxisIndex: 1,
            tooltip: {
              valueFormatter: function (value) {
                return value + " %";
              },
            },
            data: [0, 0, 0, 0, 0, 0, 20.3, 23.4, 23.0, 0, 0, 0],
          },

        ],
      };
      option && this.fourthLeft.setOption(option);
    },
    // 价格趋势
    initFRight() {
      this.fourthRight = this.$echarts.init(document.getElementById("aleftboxtbott"));

      const option = {
        xAxis: {
          type: "category",
          data: [
            "1月",
            "2月",
            "3月",
            "4月",
            "5月",
            "6月",
            "7月",
            "8月",
            "9月",
            "10月",
            "11月",
            "12月",
          ],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            smooth: true,
            data: this.fRightData,
            type: "line",
            itemStyle: {
              normal: {
                color: '#f8a947', //改变折线点的颜色
                lineStyle: {
                  color: '#f8a947' //改变折线颜色
                }
              }
            },
          },
        ],
        // 鼠标移上去 之后提示
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      };
      option && this.fourthRight.setOption(option);
    },
    changefuc() {
      this.ischange = true
      this.$nextTick(() => {
        this.initMap1()
      });
      console.log("change", this.ischange)
    }

  },
}
</script>


<style >
.changetrue {
  background-color: white;
  height: 80vh;
  width: 100%;
}

.overshadow {
  box-shadow: 0px 0px 10px 10px rgba(53, 165, 230, 70%);
  position: relative;

}

/* .overshadow1{
  z-index: 1;
} */
.content {
  width: 100%;
  height: 100%;
  color: white;
}

.i_title {

  width: 100%;
  height: 5vh;
}

.nr {
  height: 82vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.i_left {
  width: 23%;
  margin-right: 10px;
}

.i_main {
  width: 55%;
  height: 80vh;
}

.i_right {
  width: 20%;
  padding-left: 20px;
  padding-right: 10px;
}

.i_left_content {
  width: 100%;
  display: flex;

  flex-direction: row;
}

.aaa {
  background-color: #356DF2;
  height: 4px;
  margin-top: 0px;
  margin-bottom: 8px;
}

.yongtu {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.yongtu_item {
  margin-top: 10px;
  border: 2px solid rgba(109, 204, 211, 100);
  border-radius: 9px;
  box-shadow: 0px 2px 7px 0px rgba(135, 251, 252, 100);

  width: 45%;
  height: 82px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.caiwu {
  width: 8px;
  height: 20px;
  background-color: #87FBFC;
}

.survey {
  border: 1px solid rgba(136, 201, 255, 100);
  box-shadow: 0px 2px 7px 0px rgba(0, 122, 224, 100);
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  height: 100%;
}

.house_group {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
}

.house {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.house_item {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 70px;
  height: 70px;
  flex-direction: column;
  border-radius: 8px;
  background: linear-gradient(to top, #007AE0, #023267);
  border: 1px solid rgba(136, 201, 255, 100);
  box-shadow: 0px 2px 7px 0px rgba(0, 122, 224, 100);

}



/* app */
html,
body,
#app {
  padding: 0;
  margin: 0;
  background-image: url('../public/images/background.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.toptitle {
  background-image: url('../public/images/topTitle.png');
  /* background-position: center center;
    background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  background-size: cover;
  /* background-color: white; */
  width: 100%;
  height: 10vh;
  color: rgb(194, 233, 255);
  text-align: center;
  font-size: 2rem;
  line-height: 8vh;
  font-weight: 600;
}

.mytime {
  color: rgb(194, 233, 255);
  position: absolute;
  right: 2vw;
  top: 5vh;
  font-size: 1rem;

}

.gailan {
  background-image: url('../public/images/overview_title.png');
  width: 115px;
  height: 40px;
  position: absolute;
  left: calc(57.5px+15%);
  top: 8vh;
}

.appcontent {
  width: 100%;
  height: 89vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.appleft {
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: wheat; */
  height: 89vh;
  align-items: center;
}

.appmid {
  width: 44%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: lightblue; */
  height: 89vh;
  align-items: center;
}

.appright {
  width: 28%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* background-color: lightcoral; */
  height: 89vh;
  align-items: center;
}

.appleft1 {
  width: 95%;
  padding-top: 20px;
  /* height: 13.0vh; */
  border: 1px rgb(53, 165, 230) solid;
}

.appleft1>div {
  color: white;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
  border: rgb(53, 165, 230) solid 1px;
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: 1.2rem;
  font-weight: 600;
  background-color: black;
}

.appleft2 {
  width: 95%;
  height: 45vh;
  border: 1px rgb(53, 165, 230) solid;
}

.topletf {
  position: relative;
  top: -0.4vh;
  left: 1.5px;
}

.appleft3 {
  width: 95%;
  height: 24.2vh;
  border: 1px rgb(53, 165, 230) solid;
}

.appleft2-title {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  line-height: .3rem;
}

#mybox1 {
  /* background-color: lightgreen; */
  margin-top: 0vh;
  width: 100%;
  height: 40.3vh;
}

#mybox2 {
  /* background-color: lightgrey; */
  margin-top: 0vh;
  height: 22.5vh;
}

.appmid1 {
  width: 95%;
  height: 20vh;
  /* border: 1px rgb(53, 165, 230) solid; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.appmid2 {
  padding: 10px;
  width: calc(44vw - 20px);
  height: calc(45vh - 20px);
  border: 1px rgb(53, 165, 230) solid;
  position: relative;
}

.left-top {
  position: absolute;
  top: -3px;
  left: -3px;
}

.right-top {
  position: absolute;
  right: -3px;
  top: -3px;
}

.left-bottom {
  position: absolute;
  left: -3px;
  bottom: -3px;
}

.right-bottom {
  position: absolute;
  right: -3px;
  bottom: -3px;
}

.appmid3 {
  width: 100%;
  height: 21.5vh;
  border: 1px rgb(53, 165, 230) solid;
}



.appright2 {
  width: 95%;
  height: 18vh;
  border: 1px rgb(53, 165, 230) solid;
}

.appright3 {
  width: 95%;
  height: 38vh;
  border: 1px rgb(53, 165, 230) solid;
}

.appmid1-text1 {

  margin-top: 0.5rem;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 50%);
}

.appmid1-text2 {
  font-family: NumFont !important;
  font-size: 3.5rem;
  color: white;
}

.appmid1-grids {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.appright2-grids {
  display: flex;
  margin-top: 3vh;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.appmid1-grid {
  background-image: url('../public/images/frame_quantity.png');
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 5vw;
  height: 82px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: rgba(255, 255, 255, 75%);
  margin-right: 2px;
  margin-left: 2px;
}

.appright2-grid {
  width: 33%;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  color: rgba(255, 255, 255, 75%);
  margin-right: 2px;
  margin-left: 2px;
  border-right: rgba(194, 233, 255, 20%) solid 1px;
}

.appmid1-grid-text1 {

  font-size: 0.8rem;
}

.appmid1-grid-text2 {
  font-family: NumFont !important;
  color: white;
  font-size: 2rem;
  white-space: nowrap;
  margin-bottom: 1rem;
}


.mapDiv {
  background-color: white;
  height: calc(45vh - 20px);
  width: 100%;
}

#mybox4 {
  /* margin-top: 1rem; */
  height: 18.5vh;
  width: 100%;
  /* background-color: lightslategray; */
}


.appright1 {
  width: 95%;
  height: 30vh;
  border: 1px rgb(53, 165, 230) solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  /* justify-content: space-evenly; */
}

.appright1-div {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 2vh;
  background-image: url("../public/images/frame_broadcast.png");
  background-position: center center;
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 24.4vw;
  /* width: 463px; */
  height: 11vh;

  color: ghostwhite;
  padding: 5px;
}

#mybox5 {
  /* margin-top: 1rem; */
  /* height: 33.6vh; */
  height: 21vh;
  width: 100%;
  overflow-y: scroll;
  /* background-color: lightcoral; */
}

.mycards {
  margin-top: 2rem;
  height: 12vh;
  /* background-color: lightgreen; */
  display: flex;
  padding-left: 15px;
}

.mycard {
  margin-right: 0.5rem;
  color: white;
  max-width: 10rem;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: 11.5vh;
  /* background-color: lightgoldenrodyellow; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border: 1px solid rgb(53, 165, 230);
  border-right: 0;
  box-shadow: 5px 5px 25px 5px rgba(53, 165, 230, 20%) inset;
  align-items: center;
}



/* 动画 */
/* 滚动 */
.anim {
  transition: all 0.5s;
  margin-top: -14.1vh;
}

.border-anim2 {
  /* width: 100%;
  height: 100%; */
  position: relative;
  /* padding: 0.1rem; */
  /* border: 1px solid #0092fe; */
}

.border-anim-content {
  width: 100%;
  height: 100%;
  margin: 0.1rem;
  /* border: 1px solid #0092fe; */
}

.border-anim2-edge {
  position: absolute;
  /* 必须把其他边框置0，否则有默认值存在 */
  /* border: 0px solid #0092fe; */
  border: 0px solid #0092fe;
  /* border-image: linear-gradient(to right, #8f41e9, #578aef) 1; */
  box-sizing: border-box;
}

.border-anim2:hover>.border-anim2-edge {
  /* background-color: var(--border-anim-hover-color); */
}

.border-anim2-left {
  width: 3px;
  height: 100%;
  left: 0;
  border-left-width: 2px;
  animation: anim2-border-run-left 5s -2.5s linear infinite;
}

.border-anim2-top {
  height: 3px;
  width: 100%;
  top: 0;
  border-top-width: 2px;
  animation: anim2-border-run-top 5s linear infinite;
}

.border-anim2-right {
  width: 3px;
  height: 100%;
  right: 0;
  border-right-width: 2px;
  animation: anim2-border-run-right 5s -2.5s linear infinite;
}

.border-anim2-bottom {
  height: 3px;
  width: 100%;
  bottom: 0;
  border-bottom-width: 2px;
  animation: anim2-border-run-bottom 5s linear infinite;
}

@keyframes anim2-border-run-left {

  from,
  to {
    height: 0;
  }

  50% {
    height: 100%;
  }

  from,
  to,
  49.9% {
    top: 0;
    bottom: auto;
  }

  50%,
  99.9% {
    top: auto;
    bottom: 0;
  }
}

@keyframes anim2-border-run-top {

  from,
  to {
    width: 0;
  }

  50% {
    width: 100%;
  }

  from,
  to,
  49.9% {
    left: auto;
    right: 0;
  }

  50%,
  99.9% {
    left: 0;
    right: auto;
  }
}

@keyframes anim2-border-run-right {

  from,
  to {
    height: 0;
  }

  50% {
    height: 100%;
  }

  from,
  to,
  49.9% {
    top: auto;
    bottom: 0;
  }

  50%,
  99.9% {
    top: 0;
    bottom: auto;
  }
}

@keyframes anim2-border-run-bottom {

  from,
  to {
    width: 0;
  }

  50% {
    width: 100%;
  }

  from,
  to,
  49.9% {
    left: 0;
    right: auto;
  }

  50%,
  99.9% {
    left: auto;
    right: 0;
  }
}

@font-face {
  font-family: NumFont;
  /* src: url('../fonts/flatNum.ttf'); */
  src: url('../public/Digiface-Regular.ttf');
  unicode-range: U+30-39;
}

.jixie {
  font-family: NumFont !important;
  font-size: 1.2rem;
}

::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
</style>


