import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import {post, fetch,post1} from '../src/http'
import * as echarts from 'echarts';
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$post = post
Vue.prototype.$get = fetch
Vue.prototype.$post1=post1
Vue.prototype.$echarts=echarts

new Vue({
  render: h => h(App),
}).$mount('#app')
