import axios from 'axios';

// const httpUrl1='http://hyfctestapi.jx868.com/'
// 测试路径
const httpUrl='http://cffc.zianxin.com/api/'
// const httpUrl='https://245194xt96.goho.co/'

axios.defaults.timeout = 30000;


//http request 拦截器
axios.interceptors.request.use(
  config => {
    // const token = getCookie('js-cookie');注意使用的时候需要引入cookie方法，推荐js-cookie
    config.data = JSON.stringify(config.data);
    config.headers = {
      'Content-Type':'application/json'
    }
    // if(token){
    //   config.params = {'token':token}
    // }
    return config;
  },
);


//http response 拦截器
axios.interceptors.response.use(
  response => {
    
    return response;
  },
  error => {
    return Promise.reject(error)
  }
)


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url,params){
  return new Promise((resolve,reject) => {
    axios.get(httpUrl+url,{
      params:params
    })
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err)
      })
  })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url,data){
  return new Promise((resolve,reject) => {
    axios.post(httpUrl+url,data)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}
export function post1(url,data){
    return new Promise((resolve,reject) => {
        axios.post(httpUrl+url,data)
            .then(response => {
                // const json = JSONBIG({
                //     storeAsString: true
                // })
                // const res = json.parse(response)
                resolve(response.data);
            },err => {
                reject(err)
            })
    })
}
/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.patch(httpUrl+url,data)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url,data = {}){
  return new Promise((resolve,reject) => {
    axios.put(httpUrl+url,data)
      .then(response => {
        resolve(response.data);
      },err => {
        reject(err)
      })
  })
}
